import styled from '@emotion/styled';

const MinorHeader = styled.h3`
    font-size: 20px;
    margin-bottom: 0.25rem;
    font-family: inherit;
    font-weight: 300;
    line-height: 1.2;
    color: inherit;
    flex-shrink: 0;
`;

export default MinorHeader;
