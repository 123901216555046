import React from 'react';
import styled from '@emotion/styled';
import { css } from 'emotion';

type Props = {
    delay: number;
};

const LoadingDot = styled.span<Props>`
    @keyframes react-select-loading-indicator {
        0%,
        80%,
        100% {
            opacity: 0;
        }
        40% {
            opacity: 1;
        }
    }

    animation-duration: 1s;
    animation-delay: ${({ delay }) => delay}ms;
    animation-iteration-count: infinite;
    animation-name: react-select-loading-indicator;
    animation-timing-function: ease-in-out;
    background-color: #979797;
    border-radius: 8px;
    display: inline-block;
    margin-left: 4px;
    height: 4px;
    vertical-align: top;
    width: 4px;
`;

export default function LoadingIndicator() {
    return (
        <div
            className={css`
                margin-right: 8px;
                color: #979797;
                display: flex;
                padding: 1px;
                transition: color 150ms;
                align-self: center;
                font-size: 14px;
                line-height: 1;
                text-align: center;
                vertical-align: middle;
            `}>
        <LoadingDot delay={0} />
    <LoadingDot delay={160} />
    <LoadingDot delay={320} />
    </div>
);
}
