import React from "react";
import {css} from "emotion";
import Button from "./ui/Button";
import Modal, {ModalActions, ModalContent, ModalHeader} from "./Modal";
import Title from "./ui/Title";
import colours from "./ui/Colours";

type Props = {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    title: string;
    message: string;
}

export default function ConfirmationModal({
    open,
    onClose,
    onConfirm,
    title,
    message
}: Props) {

    return (
        <Modal
            isOpen={open}
            important={css`
                z-index: 40000;
            `}
            onRequestClose={onClose}
        >
            <ModalHeader>
                <Title style={{ marginTop: 0 }}>{title}</Title>
            </ModalHeader>
            <ModalContent>
                <p>{message}</p>
            </ModalContent>
            <ModalActions>
                <Button
                    colour={colours.primaryButton}
                    onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    colour={colours.saveButton}
                    onClick={onConfirm}>
                    OK
                </Button>
            </ModalActions>
        </Modal>
    )
}
