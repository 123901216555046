import React from 'react';
import { css } from 'emotion';

export default function Select(props: any) {
    return (
        <select
            {...props}
            className={css`
                border: 1px solid #cacbcc;
                border-radius: 4px;
                background-color: white;
                padding: 6px 12px;
                width: 100%;
                height: 34px;
                font-size: 14px;
            `}
        />
    );
}
