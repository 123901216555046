import React from 'react';

import { LocationProvider } from "./LocationContext";

import './App.css';
import Locations from './Locations';
import { css } from 'emotion';
import { Header } from './ui/Header';
import Logo from "./ui/Logo";
import colours from "./ui/Colours";
import {SitesProvider} from "./SitesContext";

function App() {
    return (
        <LocationProvider>
            <SitesProvider>
                <div className={css`
                    position:fixed;
                    width: 100%;
                    height: 100%;
                    background-color: ${colours.lightGrey};
                `}>
                    <Header>
                        <Logo style={{ height: 28, margin: 15 }} color={'#FFB02E'} />
                    </Header>
                    <Locations />
                </div>
            </SitesProvider>
        </LocationProvider>
    );
}

export default App;
