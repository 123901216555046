import React, {useEffect, useRef, forwardRef, useState} from 'react';
import mapboxgl from 'mapbox-gl';
import bbox from "@turf/bbox";
import {combine} from "./MapUtils";

const DEFAULT_SOURCE: any = {
    type: 'geojson',
    data: {
        type: 'FeatureCollection',
        features: []
    }
};

type Props = {
    width: string;
    height: number;
    id: number;
    geoJson: any;
    onError: () => void;
}

export default forwardRef(function Map({ width, height, id, geoJson, onError }: Props) {
    const [mapRef, setMapRef] = useState<mapboxgl.Map>();
    const [mapLoaded, setMapLoaded] = useState(false);
    const mapContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!mapboxgl.supported()) {
            return;
        }

        mapboxgl.accessToken = 'pk.eyJ1IjoiZmxlZXRlbmciLCJhIjoiY2s5NXV5eHY3MDhpNzNocGMzcXVudGZsdiJ9.gFOuNlxSePYTx4HJuMkIBA'

        if (mapContainerRef.current) {
            const map = new mapboxgl.Map({
                container: mapContainerRef.current,
                style: 'mapbox://styles/fleeteng/cjqxocjah1u7n2srsctxdl5kr',
                refreshExpiredTiles: false,
                attributionControl: false
            });

            setMapRef(map);

            map.on('load', () => {
                setMapLoaded(true);

                const locationId = id.toString()
                map.addSource(locationId, DEFAULT_SOURCE);
                map.addLayer({
                    'id': locationId,
                    'type': 'fill',
                    'source': locationId,
                    'layout': {},
                    'paint': {
                        'fill-color': '#088',
                        'fill-opacity': 0.8
                    }
                })

                map.scrollZoom.disable();
            });

            return () => map.remove();
        }
    }, [id])

    useEffect(() => {
        function fitBounds(id: string) {
            if (mapRef) {
                const location: any = mapRef.getSource(id);
                const [minX, minY, maxX, maxY] = bbox(combine(location._data));

                mapRef.fitBounds(
                    [minX, minY, maxX, maxY],
                    {
                        linear: false,
                        padding: 60,
                        duration: 0
                    },
                    {
                        userDidntInteract: false
                    }
                );
            }
        }

        if (mapRef && mapLoaded) {
            const map = mapRef;
            const locationId = id!.toString();

            try {
                if (geoJson) {
                    // @ts-ignore
                    map.getSource(locationId).setData(
                        JSON.parse(geoJson)
                    );

                    fitBounds(locationId)
                }
            } catch (ex) {
                console.log('here');
                onError();
                // @ts-ignore
                map.getSource(locationId).setData(
                    DEFAULT_SOURCE.data
                );
            }
        }
    }, [geoJson, id, mapLoaded, mapRef]);

    return (
        <div>
            <div ref={mapContainerRef} style={{ width, height }} />
        </div>
    );
});
