import styled from '@emotion/styled';

type Props = {
    danger?: boolean;
    warning?: boolean;
};

const Alert = styled.div<Props>`
    font-size: 13px;
    position: relative;
    padding: 0.75rem 1.25rem;
    margin: 1rem 0rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;

    background-color: ${props => {
    if (props.danger) return '#f8d7da';
    if (props.warning) return '#fff3cd';
    else return '#d1ecf1';
}};

    border-color: ${props => {
    if (props.danger) return '#f5c6cb';
    if (props.warning) return '#ffeeba';
    else return '#bee5eb';
}};
`;

export default Alert;
