import Http from "./Http";

export default class Api {
    token: string = '';

    constructor(token: string = '') {
        this.token = token;
    }

    endpoint = (path: string) => new Http(path, 'api.vromo.io', this.token);

    locations = () => this.endpoint('/v2/selfservice/locations')
    sites = () => this.endpoint('/v2/graph/role/sites')
}
