import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Title from './ui/Title';
import Table from './ui/Table';
import Location from "./models/Location";
import { css } from "emotion";
import LocationModal from "./LocationModal";
import colours from "./ui/Colours";
import Button from "./ui/Button";
import { useLocationProvider } from "./LocationContext";
import LoadingIndicator from "./ui/LoadingIndicator";
import qs from "querystring";
import Api from "./api/Api";
import { usePagination, useTable } from 'react-table';
import { useSitesProvider } from "./SitesContext";
import MinorHeader from "./ui/MinorHeader";

export default function Locations() {
    const locationProvider = useLocationProvider();
    const siteProvider = useSitesProvider();

    const [api, setApi] = useState<Api>();
    const [token] = useState(() => {
        const tok = qs.parse(window.location.search)['?tok'];
        return tok ? tok.toString() : null;
    });
    const [editing, setEditing] = useState(false);
    const [editedLocation, setEditedLocation] = useState(new Location());

    const columns: any = React.useMemo(
        () => [
            { Header: 'Id', accessor: 'id' },
            { Header: 'Name', accessor: 'name' },
            { Header: 'Group', accessor: 'group' },
            { Header: 'Polygon', accessor: 'polygon' },
            { Header: 'Enabled', accessor: 'enabled' }
        ],
        []
    )

    const data = useMemo(
        () => locationProvider.locations ? locationProvider.locations.map(
            (location: Location) => ({
                id: location.id,
                name: location.name,
                group: location.group,
                polygon: location.polygon ? 'Yes' : 'No',
                enabled: location.enabled ? 'Yes' : 'No'
            })
        ) : [],
        [locationProvider.locations]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex },
    } = useTable(
{
            columns: columns,
            data: data,
            initialState: { pageIndex: 0 }
        },
        usePagination
    )

    function createNewLocation(): void {
        setEditedLocation(new Location());
    }

    const fetchSites = useCallback(() => {
        if (api) {
            api.sites().get().then((res: any) => {
                siteProvider.setSites(res.items);
            })
        }
    }, [api]);

    const fetchLocations = useCallback(() => {
        if (api) {
            api.locations().get().then((res: any) => {
                locationProvider.setLocations(res['locations']);
            })
        }
    }, [api]);

    useEffect(() => {
        fetchLocations();
    }, [fetchLocations]);

    useEffect(() => {
        fetchSites();
    }, [fetchSites]);

    useEffect(() => {
        if (token) {
            setApi(new Api(token));
        }
    }, [token]);

    return (
        <>
            {!token ?
                <div style={{
                    marginLeft: '20px'
                }}>
                    <MinorHeader>Please pass your API token as a URL parameter e.g. <i>https://olo.vromo.io?tok=API_TOKEN</i></MinorHeader>
                </div>
                : <>
                    <div
                        className={css`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0px 16px;
                    `}>
                        <Title>Olo Locations</Title>
                        <div className={css`
                            margin-Top: 14px;
                        `}>
                            <Button
                                colour={colours.primaryButton}
                                onClick={() => {
                                    setEditing(true);
                                    createNewLocation();
                                }}>
                                Add New Location
                            </Button>
                        </div>
                    </div>
                    <div className={css`
                        padding: 20px;
                        margin: 10px;
                        border-radius: 10px;
                        box-shadow: 0 2px 5px 1px rgba(217, 217, 217, 0.5);
                        background-color: ${colours.background};
                    `}>
                        <div className={css`
                        width: '100%';
                        overflow: 'auto';
                        marginTop: '15px;
                    `}>
                        <Table {...getTableProps()}>
                            <thead>
                                {headerGroups.map((headerGroup: any) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column: any) => (
                                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                            {locationProvider.locations ?
                                page.map((location: any) => {
                                    prepareRow(location);
                                    return (
                                        <tr {...location.getRowProps()}>
                                            {location.cells.map((cell: any) => {
                                                return (
                                                <td
                                                    style={{ cursor: 'pointer' }}
                                                    {...cell.getCellProps()}
                                                    onClick={() => {
                                                        setEditing(true);
                                                        const location = locationProvider.locations.find(it => cell.row.original.id === it.id);
                                                        if (location) setEditedLocation(location);
                                                    }}>
                                                    {cell.render('Cell')}
                                                </td>
                                            )})}
                                        </tr>
                                    )
                                }) : <LoadingIndicator />}
                            </tbody>
                        </Table>
                        <div className={css`
                            display: flex;
                            flex-direction: row-reverse;
                            flex: 1
                        `}>
                            <div>
                                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                    {'<<'}
                                </button>{' '}
                                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                                    {'<'}
                                </button>{' '}
                                <button onClick={() => nextPage()} disabled={!canNextPage}>
                                    {'>'}
                                </button>{' '}
                                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                    {'>>'}
                                </button>{' '}
                                <span className={css`
                                    font-size: 14px;
                                `}>
                                    Page{' '}
                                    <strong>
                                        {pageOptions.length === 0 ? pageIndex : pageIndex + 1} of {pageOptions.length}
                                    </strong>{' '}
                                </span>
                            </div>
                        </div>
                        </div>
                        {
                            api ? (
                                <LocationModal
                                    api={api}
                                    open={editing}
                                    close={() => {
                                        gotoPage(pageIndex);
                                        setEditing(false);
                                    }}
                                    location={editedLocation}
                                    isNew={editedLocation.id === undefined}
                                />
                            ) : null
                        }
                    </div>
                </>
            }
        </>
    )
}
