import React, { useState } from "react";
import Location from "./models/Location";

interface LocationContext {
    locations: Array<Location>
    setLocations(data: Array<Location>): void
}

const LocationContext = React.createContext<LocationContext | null>(null);

export const LocationProvider: React.FC = function LocationProvider({ children }) {
    const [locations, setLocations] = useState();

    return (
            <LocationContext.Provider
                value={{
                    locations,
                    setLocations
                }}>
                {children}
            </LocationContext.Provider>
        );
    }

export function useLocationProvider() {
    const context = React.useContext(LocationContext);
    if (!context) {
        throw new Error('You have forgotten to use LocationProvider.');
    }
    return context;

}
