import React, {useEffect, useRef, useState} from "react";
import styled from "@emotion/styled";
import {css} from "emotion";

import Location from "./models/Location";
import Modal, {ModalActions, ModalContent, ModalHeader} from "./Modal";
import ConfirmationModal from "./ConfirmationModal";
import Map from "./Map";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from '@fortawesome/fontawesome-svg-core';
import {faToggleOff, faToggleOn} from "@fortawesome/free-solid-svg-icons";

import {Label} from "./ui/Label";
import {Text} from "./ui/Text";
import {TextArea} from "./ui/TextArea";
import {Input} from "./ui/Input";
import Title from "./ui/Title";
import Alert from "./ui/Alert";
import Button from "./ui/Button";
import colours from "./ui/Colours";
import {isValidGeoJson, transformFeature} from "./MapUtils";
import {useLocationProvider} from "./LocationContext";
import Api from "./api/Api";
import {useSitesProvider} from "./SitesContext";
import Select from "./ui/Select";

library.add(faToggleOn, faToggleOff);

const FilterRow = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5px 0px;

    label {
        flex: 1;
    }

    input,
    select {
        margin-top: 5px;
    }
`;

type Props = {
    api: Api;
    open: boolean;
    close: () => void;
    location: Location;
    isNew: boolean;
};

export default function LocationModal({ api, open, close, location, isNew }: Props) {
    const locationProvider = useLocationProvider();
    const siteProvider = useSitesProvider();

    const [editedLocation, setEditedLocation] = useState(location);
    const [site, setSite] = useState(0);
    const [name, setName] = useState(location.name);
    const [enabled, setEnabled] = useState(location.enabled);
    const [geoJson, setGeoJson] = useState(location.polygon);
    const [geoJsonError, setGeoJsonError] = useState(false);
    const mapRef = useRef();

    const [dirtyLocation] = useState(false);
    const [onDelete, setOnDelete] = useState(false);
    const [onCancel, setOnCancel] = useState(false);
    const [onError, setOnError] = useState(false);

    const [mapId, setMapId] = useState(0);

    useEffect(() => {
        setName(location.name);
        setSite(location.site ? location.site : 0);
        setEnabled(location.enabled);
        setGeoJson(JSON.stringify(location.polygon));

        setMapId(location.id ? location.id : Math.random())
        setEditedLocation(location);
    }, [location]);

    useEffect(() => {
        try {
            if (geoJson) {
                // deal with case with no FeatureCollection
                const formatted = JSON.parse(geoJson.replace(/\s/g,''));
                const transformed = transformFeature(formatted);

                editedLocation.polygon = transformed;
                setEditedLocation(editedLocation);
                setGeoJson(JSON.stringify(transformed));
                // setDirtyLocation(true);
            }
            setGeoJsonError(false);
        } catch (ex) {
            setGeoJsonError(true);
        }
    }, [editedLocation, geoJson]);

    function confirmClose() {
        if (!isNew && dirtyLocation) {
            setOnCancel(true);
        } else if (isNew || (!isNew && !dirtyLocation)) {
            cleanEdits();
            close();
        }
    }

    function cleanEdits() {
        setName(location.name);
        setEnabled(location.enabled);
        setGeoJson(JSON.stringify(location.polygon));
        setSite(location.site ? location.site : 0);
    }

    function deleteLocation() {
        api
            .locations()
            .delete({ id: editedLocation.id })
            .then(_ => {
                const updatedLocations = locationProvider.locations.filter( (location, index) => {
                    if(location.id !== editedLocation.id){
                        return location;
                    }
                });

                locationProvider.setLocations(updatedLocations);
            })

        close();
    }

    function saveLocation() {
        editedLocation.name = name;
        editedLocation.enabled = enabled;
        editedLocation.site = site === 0 ? undefined : site === -1 ? null : site;

        if (editedLocation.polygon !== undefined) {
            if (!isValidGeoJson(editedLocation.polygon)) {
                setGeoJsonError(true);
                return;
            }
        }

        if (isNew) {
            api
                .locations()
                .post(editedLocation)
                .then(async res => {
                    if (res.ok) {
                        locationProvider.setLocations(locationProvider.locations.concat(await res.json()));
                    } else {
                        setOnError(true);
                    }

                    setGeoJsonError(false);
                    close();
                });
        } else {
            api
                .locations()
                .post(editedLocation)
                .then(res => {
                    if (res.ok) {
                        const ix = locationProvider.locations.findIndex(it => it.id === editedLocation.id);
                        locationProvider.locations.splice(ix, 1, editedLocation);

                        locationProvider.setLocations(locationProvider.locations.slice());
                    } else {
                        setOnError(true);
                    }

                    setGeoJsonError(false);
                    close();
                })
        }
    }

    /* create custom input hooks */
    return (
        <Modal isOpen={open} onRequestClose={confirmClose}>
            <ModalHeader>
                <Title>{isNew ? 'New' : 'Edit'} Location</Title>
            </ModalHeader>
            <ModalContent>
                {isNew ? null : (
                    <FilterRow>
                        <Label>Enabled</Label>
                        <FontAwesomeIcon
                            icon={enabled ? 'toggle-on' : 'toggle-off'}
                            color={enabled ? 'green' : 'dark grey'}
                            onClick={() => setEnabled((prev) => !prev)}
                            style={{
                                opacity: 0.5,
                                cursor: 'pointer',
                                height: '40px',
                                width: '40px',
                                marginBottom: '5px'
                            }}
                        />
                    </FilterRow>
                )}
                {isNew ? null : (
                    <FilterRow style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                            <Label>ID</Label>
                            <Text>{editedLocation.id}</Text>
                        </div>
                    </FilterRow>
                )}
                {isNew ? null : (
                    <FilterRow>
                        <Label>Group</Label>
                        <Text>{editedLocation.group}</Text>
                    </FilterRow>
                )}
                <FilterRow>
                    <Label>Name</Label>
                    <Input
                        value={name || ''}
                        onChange={e => {
                            setName(e.target.value);
                        }}
                    />
                </FilterRow>
                <FilterRow>
                    <Label>Site (Please select the correct VROMO Site)</Label>
                    {siteProvider.sites ? (<Select
                        value={site}
                        onChange={(e: { target: { value: string; }; }) => setSite(parseInt(e.target.value))}>
                        {Array
                            .of(<option key={-1} value={-1}>None</option>)
                            .concat(siteProvider.sites.map((it, ix) => (
                                <option key={ix} value={it.id}>
                                    {it.contact?.name}
                                </option>
                            ))
                        )}
                    </Select>) : null}
                </FilterRow>
                <FilterRow>
                    <Label>Polygon</Label>
                    <div className={css`
                        position: relative;
                        margin-bottom: 10px;
                        border-radius: 5px;
                        overflow: hidden;
                    `}>
                        <Map
                            ref={mapRef}
                            height={300}
                            width={'100%'}
                            id={mapId}
                            geoJson={geoJson}
                            onError={() => {setGeoJsonError(true)}}
                        />
                    </div>
                    <div className={css`
                        margin-top: 5px;
                    `}>
                        <Label>GeoJSON (paste your GeoJSON below)</Label>
                        {geoJsonError ? (
                            <Alert danger>
                                Make sure your GeoJSON is valid before submitting. We recommend using {' '}
                                <a
                                    href={'http://geojson.io/'}
                                    target={'_blank'}
                                    rel={'noopener noreferrer'}
                                >
                                    geojson.io
                                </a>.
                            </Alert>
                        ) : null}
                        <TextArea
                            style={{ height: '150px' }}
                            value={geoJson ? geoJson : ''}
                            onChange={e => setGeoJson(e.target.value)}
                        />
                    </div>
                </FilterRow>
            </ModalContent>
            <ModalActions>
                {isNew ? null : (
                    <Button
                        colour={colours.destructiveButton}
                        style={{ marginRight: 'auto' }}
                        onClick={() => setOnDelete(true)}>
                        Delete
                    </Button>
                )}
                <Button
                    colour={colours.primaryButton}
                    onClick={confirmClose}>
                    Cancel</Button>
                <Button
                    disabled={geoJsonError}
                    colour={colours.saveButton}
                    onClick={saveLocation}>
                    Save Location
                </Button>
            </ModalActions>
            <ConfirmationModal
                open={onDelete}
                onClose={() => {
                    setOnDelete(false);
                }}
                onConfirm={() => {
                    setOnDelete(false);
                    deleteLocation()
                }}
                title={'Delete Location'}
                message={'Are you sure you want to delete this location?'}
            />
            <ConfirmationModal
                open={onCancel}
                onClose={() => setOnCancel(false)}
                onConfirm={() => {
                    setOnCancel(false);
                    cleanEdits();
                    close();
                }}
                title={'Save Changes'}
                message={'Are you sure you want to leave without saving your changes?'}
            />
            <ConfirmationModal
                open={onError}
                onClose={() => setOnError(false)}
                onConfirm={() => {
                    setOnError(false);
                }}
                title={'Error'}
                message={'There was an error with trying to save your changes.'}
            />
            </Modal>
    )
}
