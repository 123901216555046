import React from 'react';

type Props = {
    style: object;
    color: string;
}

export default function Logo({ style, color }: Props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 311.9 63.5"
            style={style}>
            <g>
                <path
                    fill={color}
                    d="M212.7 60.5L183.9 3.4h-10.7v57.1h9.7v-38l19.4 38zM231 3.4h-1L209.8 43l5.4 10.6L231 22.5v38h9.7V3.4zM82.4 39.2V39C90.2 36.8 95 31.1 95 21.7c0-10.6-5.9-18.5-19.3-18.6l-4.3 8.7h2.5c7 0 11 3.6 11 9.7 0 5.7-3.8 9.7-11.7 9.7H61.8L57.4 40h14.3l13.8 20.5h11.7L82.4 39.2z"
                />
                <path
                    fill={color}
                    d="M38.5 56L11.7 3.2H1l29.2 57.1h10.5L38.5 56zM57.8 3.2L37.7 42.9 43 53.5 68.6 3.2z"
                />
                <path
                    fill={color}
                    d="M145.9 4.7l-4.7 8.8c-9-3.4-19.4.3-24 9-4.7 8.7-2 19.4 5.8 25l-4.7 8.8c-12.7-8.3-17.1-25-9.8-38.6 7.1-13.4 23.5-19 37.4-13zM127.4 50c9 3.4 19.4-.3 24-9 4.7-8.7 2-19.4-5.8-25l4.7-8.8c12.7 8.3 17.1 25 9.8 38.6A29.57 29.57 0 0 1 122.5 59l4.9-9z"
                />
                <path
                    fill={color}
                    d="M292.2 4.7l-4.7 8.8c-9-3.4-19.4.3-24 9-4.7 8.7-2 19.4 5.8 25l-4.7 8.8c-12.7-8.3-17.1-25-9.8-38.6 7.1-13.4 23.5-19 37.4-13zM273.7 50c9 3.4 19.4-.3 24-9 4.7-8.7 2-19.4-5.8-25l4.7-8.8c12.7 8.3 17.1 25 9.8 38.6A29.57 29.57 0 0 1 268.8 59l4.9-9z"
                />
            </g>
        </svg>
    );
}
