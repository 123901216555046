import url from "url";
import * as queryString from "querystring";

export default class Http {
    path: string;
    host: string;
    token: string;

    constructor(path: string, host = "prod-api.spatula.io", token: string) {
        this.path = path;
        this.host = host;
        this.token = token;
    }

    fullURL() {
        return url.resolve(`https://${this.host}/`, this.path);
    }

    async get(options = {}) {
        return await (await fetch(`${this.fullURL()}?tok=${this.token}`, { method: 'GET', ...options })).json();
    }

    async post(data: any, options = {}) {
        return (await fetch(`${this.fullURL()}?tok=${this.token}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
            ...options
        }));
    }

    async patch(data: any, options = {}) {
        return (await fetch(`${this.fullURL()}?tok=${this.token}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
            ...options
        }));
    }

    async delete(data: any, options = {}) {
        const encodedData = queryString.stringify(data);
        return (await fetch(`${this.fullURL()}?tok=${this.token}&${encodedData}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({}),
            ...options
        }));
    }
}
