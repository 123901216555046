import styled from "@emotion/styled";

export const Header = styled.div`
    min-height: 45px;
    margin-bottom: 0 !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white !important;
    flex-shrink: 0;

    border-bottom: 1px solid rgba(151, 151, 151, 0.38);
    box-shadow: 0 2px 4px 0 rgba(233, 233, 233, 0.5);
`;
