const colours = {
    background: '#fff',
    container: '#d8d8d8',

    offWhite: '#fcfcfc',
    lightGrey: '#eee',
    grey: '#cccccc',
    mediumGrey: '#737373',
    darkGrey: '#10100E',

    primaryButton: '#8779BD',
    destructiveButton: '#FF4A4A',
    saveButton: '#FFB023',

    text: {
        light: '#f6f6f6',
        dark: '#474747'
    }
};

export default colours;
