import styled from '@emotion/styled';

const Title = styled.h1`
    font-size: 2rem;
    margin-bottom: 0.25rem;
    font-family: inherit;
    font-weight: 300;
    line-height: 1.2;
    color: inherit;
    flex-shrink: 0;
`;

export default Title;
