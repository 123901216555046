import styled from '@emotion/styled';

const colour = (props: ButtonProps) => {
    return props.colour;
};

type ButtonProps = {
    colour?: string;
};

const Button = styled.button<ButtonProps>`
    color: white;
    background-color: ${colour};

    border: none;
    padding: 10px 20px;
    border-radius: 4px;

    box-shadow: 0 2px 5px 1px rgba(150, 150, 150, 0.5);
    font-size: 14px;
    font-weight: 700;

    transition: all 150ms ease-in-out;

    &:hover {
        cursor: pointer;
        box-shadow: 0 2px 5px 2px rgba(150, 150, 150, 0.5);
    }

    &:hover,
    &:active,
    &:focus {
        color: white;
    }

    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }
`;

export default Button;
