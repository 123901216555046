import React from 'react';
import ReactModal from 'react-modal';

import { css, keyframes } from 'emotion';
import styled from '@emotion/styled';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

library.add(faTimesCircle);

const fadeInUp = keyframes`
from {
    opacity: 0;
    transform: translateY(20%);
}

to {
    opacity: 1;
    transform: translateY(0%);
}
`;

const fadeOutDown = keyframes`
    from {
        opacity: 1;
        transform: translateY(0%);
    }

    to {
        opacity: 0;
        transform: translateY(20%);
    }
`;

const content = css`
    position: absolute;
    right: auto;
    bottom: auto;
    border-radius: 10px;
    padding: 40px;
    border: none;
    background-color: white;
    outline: none;
    min-width: 600px;

    @media (max-width: 1000px) {
        overflow: auto;
        max-height: 95vh;
        padding: 30px;
        min-width: 0;
        right: 20px;
        left: 20px;
    }
`;

const overlay = css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 20000;
    opacity: 0;
    transition: opacity 250ms ease-in;
`;

export const ModalHeader = styled.div`
    margin-bottom: 20px;

    h1,
    h2,
    h3 {
        margin: 0;
    }
`;

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 70vh;
    overflow: auto;
    padding: 2px;

    @media (max-width: 500px) {
        max-height: unset;
    }
`;

export const ModalActions = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;

    button:not(:last-child) {
        margin-right: 10px;
    }

    @media (max-width: 500px) {
        margin-top: 10;
        flex-direction: column;
        align-items: stretch;

        button {
            margin-right: 0px !important;
            margin: 5px 0px;
        }
    }
`;

export const MODAL_CLOSE_TIMEOUT = 250;

type ModalProps = {
    onClose: () => void
}

export function ModalClose({ onClose }: ModalProps) {
    return (
        <>
            <FontAwesomeIcon
                icon="times-circle"
                onClick={onClose}
                style={{
                    transition: 'opacity 150ms ease-in',
                    opacity: 0.5,
                    cursor: 'pointer',
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    margin: '40px 20px',
                    height: '30px',
                    width: '30px'
                }}
            />
        </>
    );
}

type Props = {
    allowEvents?: boolean;
    isOpen: boolean;
    onRequestClose?: () => void;
    important?: string;
    children: React.ReactNode;
};

export default function Modal({
    allowEvents = false,
    isOpen = false,
    onRequestClose,
    children,
    important,
    ...props
}: Props) {
    return (
        <div
            onClick={allowEvents ? () => {} : e => e.stopPropagation()}
            onDoubleClick={allowEvents ? () => {} : e => e.stopPropagation()}
            style={{ display: 'block' }}>
            <ReactModal
                {...props}
                closeTimeoutMS={MODAL_CLOSE_TIMEOUT}
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                overlayClassName={{
                    base: `${overlay} ${important}`,
                    afterOpen: css`
                        opacity: 1;
                    `,
                    beforeClose: css`
                        opacity: 0;
                        pointer-events: none;
                    `
                }}
                className={{
                    base: content,
                    afterOpen: css`
                        animation: ${fadeInUp} ${MODAL_CLOSE_TIMEOUT}ms ease-in-out;
                    `,
                    beforeClose: css`
                        animation: ${fadeOutDown} ${MODAL_CLOSE_TIMEOUT}ms ease-in-out;
                    `
                }}>
                {onRequestClose ? <ModalClose onClose={onRequestClose} /> : null}
                {children}
            </ReactModal>
        </div>
    );
}
