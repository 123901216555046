import { Feature, FeatureCollection, Geometry } from '@turf/helpers';

export function combine(...featureCollections: FeatureCollection[]): FeatureCollection {
    return featureCollection(featureCollections.flatMap(it => it.features));
}

export function feature(geometry: Geometry, properties = {}): Feature {
    return {
        type: 'Feature',
        geometry,
        properties
    };
}

export function featureCollection(features: Feature[] = []): FeatureCollection {
    return {
        type: 'FeatureCollection',
        features
    };
}

export function transformFeature(geoJson: any): any {
    if (geoJson.type === "Feature") {
        return featureCollection([feature(geoJson.geometry, geoJson.properties)]);
    } else {
        return geoJson;
    }
}

export function isValidGeoJson(geoJson: any): Boolean {
    if (!geoJson.features || geoJson.type !== "FeatureCollection") {
        return false;
    }

    const features = geoJson.features;
    if (
        features.length !== 1 || !features[0].geometry || !features[0].type ||
        features[0].type !== "Feature"
    ) {
        return false;
    }

    const geometry = features[0].geometry;
    if (
        (!geometry.type || geometry.type !== "Polygon") ||
        (!geometry.coordinates || !geometry.coordinates)
    ) {
        return false;
    }

    const coords = geometry.coordinates;
    return checkArrayOfArrays(coords);
}

function checkArrayOfArrays(array: Array<any>): Boolean {
    return array.every(function(x) {
        return Array.isArray(x) && x.every(y => Array.isArray(y) && y.every(item => typeof item === "number"));
    });
}
