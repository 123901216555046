import styled from '@emotion/styled';
import colours from "./Colours";

const Table = styled.table`
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    border-spacing: 0;
    background-color: transparent;

    th {
        border-top: 0;
        line-height: 1.42857;
        padding: 8px;
        color: ${colours.grey};
        font-weight: normal;
        font-size: 14px;
        vertical-align: top;
        text-align: left;
        border-bottom: 1px solid ${colours.lightGrey};
    }

    tbody {
        tr {
            transition: all 250ms ease-in-out;
            background-color: ${colours.background};

            :hover {
                background-color: ${colours.lightGrey};
            }
        }

        td {
            font-size: 14px;
            line-height: 1.42857;
            padding: 10px;
            vertical-align: center;

            :first-of-type {
                border-radius: 6px 0px 0px 6px;
            }

            :last-of-type {
                border-radius: 0px 6px 6px 0px;
            }
        }
    }
`;

export default Table;
