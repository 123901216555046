import React, { useState } from "react";
import Site from "./models/Site";

interface SitesContext {
    sites: Array<Site>
    setSites(data: Array<Site>): void
}

const SitesContext = React.createContext<SitesContext | null>(null);

export const SitesProvider: React.FC = function SitesProvider({ children }) {
    const [sites, setSites] = useState();

    return (
        <SitesContext.Provider
            value={{
                sites,
                setSites
            }}>
            {children}
        </SitesContext.Provider>
    );
}

export function useSitesProvider() {
    const context = React.useContext(SitesContext);
    if (!context) {
        throw new Error('You have forgotten to use SiteProvider.');
    }
    return context;

}
