import styled from "@emotion/styled";
import colours from "./Colours";

export const TextArea = styled.textarea`
    padding: 0px;
    margin-top: 5px;
    border: 1px solid ${colours.lightGrey};
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    background-color: white;
    display: block;
    outline: none;
    width: 100%;
    height: 34px;
    font-size: 13px;

    ::placeholder {
        color: ${colours.grey};
        transition: all 0ms.15s ease-in-out 0s;
    }

    &:hover:enabled {
        border-color: #bebec3;
    }

    &:focus {
        border-color: #2c9ee0 !important;
        box-shadow: none;
        outline: 0;

        ::placeholder {
            color: #e5e5e6;
        }
    }
`;
